import styled from 'styled-components';
import React, { FC } from 'react';
import { WindowLocation } from '@gatsbyjs/reach-router';
import withRouter from '../../../util/withRouter';

import { ReactComponent as Facebook } from '../../../../assets/images/Elsewhen_CaseStudy_SocialIcon_Facebook.svg?as=component';
import { ReactComponent as Twitter } from '../../../../assets/images/Elsewhen_CaseStudy_SocialIcon_Twitter.svg?as=component';
import { ReactComponent as LinkedIn } from '../../../../assets/images/Elsewhen_CaseStudy_SocialIcon_LinkedIn.svg?as=component';
import { ReactComponent as Mail } from '../../../../assets/images/Elsewhen_CaseStudy_SocialIcon_Email.svg?as=component';

const StyledA = styled.a`
  min-width: 1rem;
  min-height: 1rem;
  display: flex;
  justify-content: center;
`;

const StyledSocial = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 0;
  display: flex;
  justify-content: flex-start;

  li {
    padding: 0.75rem;
    margin: 0 0.5rem;
    max-height: 2.5rem;
    position: relative;

    &:first-of-type {
      margin-left: 0;
    }
  }

  svg path {
    fill: ${({ theme }) => theme.fg};
    opacity: 0.6;
  }
`;

export const useShareIcons = (location: WindowLocation, type?: string) => {
  const slug = location.pathname;
  const url = `https://www.elsewhen.com${slug}`;
  const title = `Elsewhen ${type}`;
  const shareText = `Check out this ${type} on Elsewhen: ${url}`;
  const e = encodeURIComponent;

  return [
    <StyledA
      href={`https://www.facebook.com/sharer/sharer.php?u=${e(url)}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      <Facebook />
    </StyledA>,
    <StyledA
      href={`mailto:?subject=${title}&body=${shareText}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      <Mail />
    </StyledA>,
    <StyledA
      href={`https://www.linkedin.com/shareArticle?mini=true&url=${e(
        url
      )}&title=${e(title)}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      <LinkedIn />
    </StyledA>,
    <StyledA
      href={`https://twitter.com/share?status=${e(shareText)}&url=${e(url)}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      <Twitter />
    </StyledA>,
  ];
};

type Props = {
  location: WindowLocation;
  type?: string;
  className?: string;
};

export const SocialIconsLocation: FC<Props> = ({
  location,
  type = 'case study',
  className,
}) => {
  const icons = useShareIcons(location, type);
  return (
    <StyledSocial className={className}>
      {icons.map((icon, index) => {
        return <li key={index}>{icon}</li>;
      })}
    </StyledSocial>
  );
};

export default withRouter(SocialIconsLocation);
