import React, { FC, ReactNode, useEffect } from 'react';
import {
  StyledCloseButton,
  StyledMainList,
  StyledMenu,
} from './OverlayMenu.styles';
import FadeInMenuItem from './FadeInMenuItem';
import { CSSTransition } from 'react-transition-group';
import ContentWrapper from '../../../old/components/style-components/ContentWrapper';
import { ReactComponent as SvgClose } from '../../../../assets/icons/close.svg?as=component';

interface MenuProps {
  appear?: boolean;
  close: () => void;
  items: ReactNode[];
  className?: string;
}

const Menu: FC<MenuProps> = ({ appear = true, items, className, close }) => {
  useEffect(() => {
    // prevent page from scrolling when scrolling the menu:
    const scrollY = window.scrollY;
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollY}px`;
    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, scrollY || 0);
    };
  }, []);

  return (
    <CSSTransition
      timeout={150}
      in={appear}
      classNames="menu-transition"
      appear
      mountOnEnter
      unmountOnExit
    >
      <StyledMenu className={className}>
        <StyledCloseButton aria-label="Menu" onClick={close}>
          <SvgClose />
        </StyledCloseButton>
        <ContentWrapper>
          <StyledMainList>
            {items.map((item: ReactNode, index: number) => (
              <li key={index}>
                <FadeInMenuItem appear={appear} delay={index + 1} element="li">
                  {item}
                </FadeInMenuItem>
              </li>
            ))}
          </StyledMainList>
        </ContentWrapper>
      </StyledMenu>
    </CSSTransition>
  );
};

export default Menu;
